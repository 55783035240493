"use client";

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

interface ModalProps {
  open: boolean;
  onClose: (close: boolean) => void;
  children?: React.ReactNode;
  closeModalText: string;
  heading: string;
}

export default function Modal(props: ModalProps) {
  const { open, onClose, children, closeModalText, heading } = props;

  function closeModal() {
    onClose(false);
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="border-b border-gray-200 bg-white py-2 mb-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {heading}
              </h3>
            </div>
            <div className="h-72">{children}</div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={closeModal}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {closeModalText}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

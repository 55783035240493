import { useState } from "react";
import {
  Bars3Icon,
  BoltIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "@/hooks/use-auth";
import "../app/globals.css";
import Modal from "@/components/modal";
import { OrgListOrg } from "@/types/org.types";

const navigation = [
  { name: "Channels", href: "/channels", icon: BoltIcon, current: true },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [openChangeOrgModal, setOpenChangeOrgModal] = useState(false);

  const { logout, orgsList, changeOrg } = useAuth();

  return (
    <>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-custom-gray px-6">
          <div className="flex h-18 shrink-0 items-center justify-start py-4">
            <img
              alt="sc-logo"
              src="/logo-144x144.png"
              className="h-14 w-auto"
            />
            <img
              alt="sc-text"
              src="https://studio.staging.eu.next.sc/assets/screencloud-white-c8d71414.svg"
              className="h-5 w-auto"
            />
          </div>
          <nav className="flex flex-1 flex-col gap-y-5 h-full">
            <ul role="list" className="flex flex-1 flex-col gap-y-7 h-full">
              <li className="flex flex-col justify-between h-full">
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:bg-gray-800 hover:text-white",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>

                <ul role="list" className="-mx-2 space-y-1 mb-7">
                  <li>
                    <div
                      className={classNames(
                        "text-gray-400 hover:bg-gray-800 hover:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 hover:cursor-pointer mb-2"
                      )}
                      onClick={() => setOpenChangeOrgModal(true)}
                    >
                      <ArrowPathRoundedSquareIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                      Switch Organisation
                    </div>
                    <div
                      className={classNames(
                        "text-gray-400 hover:bg-gray-800 hover:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:cursor-pointer"
                      )}
                      onClick={logout}
                    >
                      <ArrowRightEndOnRectangleIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                      Log out
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-[var(--color-gray-950)]/80 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>
        <button onClick={logout}>Log out</button>
      </div>

      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>

      <Modal
        open={openChangeOrgModal}
        onClose={() => setOpenChangeOrgModal(!openChangeOrgModal)}
        heading={"Select an Organisation"}
        closeModalText="Cancel"
      >
        <ul role="list" className="-mx-2 space-y-1">
          {orgsList.map((org: OrgListOrg) => (
            <li key={org.id}>
              <div
                onClick={() => changeOrg(org.id)}
                className={classNames(
                  org.isCurrent
                    ? "bg-gray-50 text-indigo-600"
                    : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                  "group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 hover:cursor-pointer"
                )}
              >
                {org.name}
              </div>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
}

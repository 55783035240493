"use client";

import React, { createContext, useState, useEffect, FC } from "react";
import { AuthManager, IAuthSession } from "@screencloud/auth-sdk";
import { useRouter, usePathname } from "next/navigation";
import { OrgListOrg } from "@/types/org.types";
import { RawStudioToken, StudioToken } from "@/types/auth.types";
import { useInterval } from "usehooks-ts";

interface AuthContextValue {
  logout: () => void;
  currentSession: IAuthSession | null;
  orgsList: OrgListOrg[];
  changeOrg: (orgId: string) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  logout: () => {},
  currentSession: null,
  orgsList: [],
  changeOrg: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const authServiceFe = `${process.env.NEXT_PUBLIC_AUTH_APP_BASE_URL}`;
const authServiceFeWithRedirect = `${authServiceFe}?redirect=${process.env.NEXT_PUBLIC_BASE_URL}`;

const createAuthManager = () => {
  return new AuthManager({
    debug: process.env.NEXT_PUBLIC_AUTH_DEBUG === "true",
    autoRefresh: process.env.NEXT_PUBLIC_AUTH_REFRESH_SESSION === "true",
    autoSync: process.env.NEXT_PUBLIC_AUTH_SYNC_SESSION === "true",
    service: { url: `${process.env.NEXT_PUBLIC_AUTH_SERVICE_BASE_URL}` },
    frontend: { url: authServiceFe },
  });
};

const getStudioJwts = async (authToken: string) => {
  try {
    const response = await fetch(`/api/auth`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch JWTs");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching JWTs:", error);
    throw error;
  }
};

const getOrgsFromTokens = (tokens: RawStudioToken[], currentOrg: string) => {
  return tokens.map((token) => ({
    id: token.tokenPayload.org_id as string,
    name: token.tokenPayload.org_name as string,
    isCurrent: token.tokenPayload.org_id === currentOrg,
  }));
};

const getOrgTokens = (tokens: RawStudioToken[]) => {
  const orgTokens: StudioToken = {};
  tokens.forEach((token) => {
    orgTokens[token.tokenPayload.org_id] = token.token;
  });
  return orgTokens;
};

const getOrgsListAndOrgTokens = (tokens: any, currentOrg: string) => {
  const orgsList = getOrgsFromTokens(tokens.accessTokens, currentOrg);
  const orgTokens = getOrgTokens(tokens.accessTokens);
  return { orgsList, orgTokens };
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();

  const [currentSession, setCurrentSession] = useState<IAuthSession | null>(
    null
  );
  const [orgsList, setOrgsList] = useState<OrgListOrg[]>([]);
  const [studioTokens, setStudioTokens] = useState<StudioToken>({});

  const getAuthSession = async () => {
    const auth = createAuthManager();
    const authSession = await auth.get();

    if (authSession) {
      setCurrentSession(authSession);

      const studioTokens = await getStudioJwts(authSession.token);

      const currentOrg = localStorage.getItem("currentOrg");

      const defaultOrg = studioTokens.accessTokens[0].tokenPayload.org_id;
      if (!currentOrg) {
        localStorage.setItem("currentOrg", defaultOrg);
      }

      const { orgsList, orgTokens } = getOrgsListAndOrgTokens(
        studioTokens,
        currentOrg ?? defaultOrg
      );
      setOrgsList(orgsList);
      setStudioTokens(orgTokens);

      if (studioTokens) {
        localStorage.setItem("authToken", studioTokens.accessTokens[0].token);
      }
      if (pathname === "/") {
        router.push("/channels");
      }
    } else {
      router.push(authServiceFeWithRedirect);
    }
  };

  const changeOrg = (orgId: string) => {
    localStorage.setItem("authToken", studioTokens[orgId]);
    localStorage.setItem("currentOrg", orgId);
    window.location.reload();
  };

  const logout = async () => {
    const auth = createAuthManager();
    await auth.logout();
    router.push(authServiceFeWithRedirect);
  };

  useEffect(() => {
    getAuthSession();
  }, []);

  useInterval(() => {
    getAuthSession();
  }, 60 * 25);

  return (
    <AuthContext.Provider
      value={{ logout, currentSession, orgsList, changeOrg }}
    >
      {children}
    </AuthContext.Provider>
  );
};

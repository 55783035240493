"use client";
import React, { Suspense } from "react";
import { AuthProvider } from "@/contexts/auth-context";
import Layout from "@/layouts";
import "./globals.css";
import Loading from "./loading";

export default function RootLayout({ children }: any) {
  return (
    <html lang="en" className="h-full bg-white">
      <body className="h-full">
        <AuthProvider>
          <Layout>
            <Suspense fallback={<Loading />}>{children}</Suspense>
          </Layout>
        </AuthProvider>
      </body>
    </html>
  );
}
